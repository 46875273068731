const leaflets = {
    "magazziniMaurys": {
        "config": {
            "primary-color": "rgb(1, 45, 86)",
            "secondary-color": "rgb(253, 241, 0)",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": require("assets/images/logo-maurys.jpg"),
            "serverPath": "Maurys.volantinointerattivo.net/",
            "pdfPath": "/media/pdf/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino interattivo di Magazzini Maury's!",
            "shareFlyerURL": "https://Maurys.volantinointerattivo.net",
            "shareProductSentence": "Guarda questa fantastica offerta!",
            "ogTitleMeta": "Volantino Interattivo di Magazzini Maury's",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://Maurys.volantinointerattivo.net/media/images/maurys_fb.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-22",
            "release_id": "1",
            "send_grocery_list_to_market": false,
            "grocery_list_min_value": 25,
            "client_id": 17,
            "signboard_id": 32,
            "has_pages": true,
            "hide_grocery_list": false,
            "hide_searchbar": false,
            "line_through": false,
            "category_banner": "",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
            "type": "leaflet"
        },
        "leaflet": {
            "name": "Magazzini Maury's_Demo",
            "code": "456",
            "id_campaign": "456",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/Indice_maurys_Tavola_disegno_1_ZTkP7Xt.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 30.264468567490454,
                            "left": 13.276977419268277,
                            "width": 73.92955033561549,
                            "height": 5.666307321900831
                        }
                    },
                    {
                        "page": 4,
                        "blueprint": {
                            "top": 35.985627988683106,
                            "left": 13.248833204275027,
                            "width": 73.92955033561549,
                            "height": 5.666307321900831
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 41.748104437139006,
                            "left": 13.26592328761347,
                            "width": 73.92955033561549,
                            "height": 5.666307321900831
                        }
                    },
                    {
                        "page": 8,
                        "blueprint": {
                            "top": 47.80362151212826,
                            "left": 13.018138282783369,
                            "width": 73.92955033561549,
                            "height": 5.666307321900831
                        }
                    },
                    {
                        "page": 10,
                        "blueprint": {
                            "top": 53.83060060851565,
                            "left": 13.093022250099668,
                            "width": 73.92955033561549,
                            "height": 5.283391080104339
                        }
                    },
                    {
                        "page": 12,
                        "blueprint": {
                            "top": 59.277032944905486,
                            "left": 12.910070716925294,
                            "width": 73.92955033561549,
                            "height": 5.581972189418004
                        }
                    },
                    {
                        "page": 16,
                        "blueprint": {
                            "top": 65.0697544396307,
                            "left": 13.149812498083293,
                            "width": 73.92955033561549,
                            "height": 5.581972189418004
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Igiene Casa",
                    "subcategories": [
                        "Bucato",
                        "Extra",
                        "Superfici",
                        "Pavimenti"
                    ]
                },
                {
                    "category_name": "Igiene Persona",
                    "subcategories": [
                        "Orale",
                        "Capelli",
                        "Viso",
                        "Corpo",
                        "Intima",
                        "Solari"
                    ]
                },
                {
                    "category_name": "Casa e Cucina",
                    "subcategories": [
                        "Altro"
                    ]
                },
                {
                    "category_name": "Pet",
                    "subcategories": [
                        "Food",
                        "Care"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_1_R7YYKQl.jpg",
                    "products": [
                        {
                            "item_id": "cstm44648237",
                            "field1": "Fabuloso",
                            "field2": "Ammorbidente",
                            "field3": "3LT Assortito",
                            "field4": "",
                            "description": "Fabuloso Ammorbidente 3LT Assortito",
                            "grammage": 3000,
                            "price": "1.88",
                            "price_label": "€1,88",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fresco Mattino",
                                "Vaniglia"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 19.672668353692952,
                                "left": 2.4081632653061225,
                                "width": 47.110633727175085,
                                "height": 30.364652551475533
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/images_EAVpjon.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/fabuloso-ammorbidente-vaniglia-3-litri_NYfjR4k.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_JIg7GFp_yy3HJat.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm60544238",
                            "field1": "ACE LAVATRICE",
                            "field2": "10 Lavaggi",
                            "field3": "Assortiti",
                            "field4": "",
                            "description": "ACE LAVATRICE 10 Lavaggi Assortiti",
                            "grammage": null,
                            "price": "1.59",
                            "price_label": "€1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Colorati",
                                "Bianchi e Colorati"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 19.54672381323463,
                                "left": 50.000111594726704,
                                "width": 47.838919617404116,
                                "height": 30.62320064073426
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/71NU6tvuTL._AC_SX569__HzWCtan.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/61lJlOOM6cL._AC_SX522__jr55Hdx.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/61DYBNWN7nL._AC_SL1500__3ud0K0w.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_aUdknAO_a5Xc3yE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "Condiglio Da ACE - Come Togliere Macchie di Pennarello dai Vestiti",
                                    "data": "https://interattivo.s3.amazonaws.com/7x8O664UHz/video/Come_togliere_le_macchie_di_pennarelli_dai_vestiti.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm82502501",
                            "field1": "LISTERINE",
                            "field2": "Colluttorio",
                            "field3": "500ML Assortiti",
                            "field4": "",
                            "description": "LISTERINE Colluttorio 500ML Assortiti",
                            "grammage": 500,
                            "price": "1.99",
                            "price_label": "€1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Multiazione",
                                "Difesa Denti e Gengive"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 50.21739130434782,
                                "left": 2.4369565217391305,
                                "width": 30.696739130434786,
                                "height": 28.26086956521739
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_7hJQg3Y_VRrcFJ6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "Listerine",
                                    "data": "https://www.listerine.it/prodotti"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm18252743",
                            "field1": "DIMENSION",
                            "field2": "Shampoo",
                            "field3": "350ML Assortiti",
                            "field4": "",
                            "description": "DIMENSION Shampoo 350ML Assortiti",
                            "grammage": 350,
                            "price": "0.59",
                            "price_label": "€0,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Tutti i Tipi di Capelli/Olio di Avocado",
                                "Tutti i Tipi di Capelli/Olio di Cocco",
                                "Shampoo e Balsamo/Capelli Normali",
                                "Shampoo e Balsamo/Capelli Lisci"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 50.45660127329817,
                                "left": 33.50448919232948,
                                "width": 34.741268881014804,
                                "height": 27.77825790529965
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/s_D8l30mi.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/ss_0fDkh0D.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/ssssss_Jjg5EEu.png",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/sss_q5y29zb_PMmVBe8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_7M379Ke_NkvU57k.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "Curiosità su Dimension by Lux",
                                    "data": {
                                        "titolo": "Curiosità su Dimension by Lux",
                                        "testo": "Dimension by Lux ha una linea di shampoo arricchita con un concentrato di estratti naturalmente presenti nella frutta: glucosio, vitamine C ed E, acido delicato della frutta. Lo shampoo nutre il capello dalla radice, dona forza e corposità e rende la superficie del capello più liscia per una migliore lucentezza. Giorno dopo giorno i tuoi capelli saranno forti e splendenti, pieni di salute.\n\nFonte:\"Tigota.it\"",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/7x8O664UHz/images/de.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm19901411",
                            "field1": "AZ DENTIFRICIO",
                            "field2": "Idea Natura",
                            "field3": "75ML Assortiti",
                            "field4": "",
                            "description": "AZ DENTIFRICIO Idea Natura 75ML Assortiti",
                            "grammage": 75,
                            "price": "0.49",
                            "price_label": "€0,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Eucalipto Camomilla e Salvia",
                                "Forza Marina + Minerali"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 50.32608695652172,
                                "left": 68.10652173913043,
                                "width": 29.542391304347827,
                                "height": 27.82608695652174
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/sss_jJ2AGNH_dUA3qwO.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/ss_yQT8N70.png",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_RrG0B47_VFyUKav.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "Dentifricio AZ Idea Natura",
                                    "data": "https://www.az-oralb.it/it-it/prodotti/dentifricio-e-collutorio/dentifricio-idea-natura-eucalipto-camomilla-salvia"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_2_TgBKlPz.jpg",
                    "products": [
                        {
                            "item_id": "cstm02565328",
                            "field1": "DASH ACTILIFT CLASSICO",
                            "field2": "Fustone",
                            "field3": "52 Misurini",
                            "field4": "",
                            "description": "DASH ACTILIFT CLASSICO Fustone 52 Misurini",
                            "grammage": null,
                            "price": "6.99",
                            "price_label": "€6,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 9.02173913043477,
                                "left": 2.4369565217391305,
                                "width": 24.283695652173915,
                                "height": 19.67391304347826
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_oB1tJBh_9y6LZLw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm25439846",
                            "field1": "MAURY'S",
                            "field2": "Profumatore Bucato Concentrato",
                            "field3": "400ML Assortiti",
                            "field4": "",
                            "description": "MAURY'S Profumatore Bucato Concentrato 400ML Assortiti",
                            "grammage": 400,
                            "price": "4.99",
                            "price_label": "€4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Argan ",
                                "Lavanda Alpina",
                                "Muschio Bianco",
                                "Glicine del Giappone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 9.118773946360138,
                                "left": 27.114240102171134,
                                "width": 33.85996168582375,
                                "height": 19.32311621966794
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_J3aadd1_PcX3nZ4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "MAURY'S PROFUMATORE BIANCHERIA E PAVIMENTI 400 ML CONCENTRATO CON EROGATORE",
                                    "data": {
                                        "specifiche": "Deodorante e profumatore per le superfici e i tessuti, Grazie alla qualità degli olii essenziali. la profumazione è davvero favolosa, Inebriante aggiunto in lavatrice per un profumo che dura a lungo, Concentrazione massima del prodotto: ti garantisce risultati e risparmio, Durata: 65 lavaggi, Favoloso aggiunto direttamente nell'acqua del secchio per i pavimenti per averli sempre freschi e puliti, Made in Italy, Formato: 400 ml, Profumo: ARGAN",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/7x8O664UHz/images/dedede.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm17436756",
                            "field1": "MASTRO LINDO",
                            "field2": "Con Candeggina Cucina Limone",
                            "field3": "Spray 500ML",
                            "field4": "",
                            "description": "MASTRO LINDO Con Candeggina Cucina Limone Spray 500ML",
                            "grammage": 500,
                            "price": "0.88",
                            "price_label": "€0,88",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Cucina ",
                                "Bagno",
                                "Con Candeggina",
                                "Limone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 9.118773946360138,
                                "left": 61.38918546826299,
                                "width": 36.08390607535309,
                                "height": 19.32311621966794
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/71SFPeEstxL._AC_SY879__rYtQS19.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/13490_RdPGFTw.png",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/5410076963515_70_2vcANFk.png",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/mastro-lindo-spray-sgrassatore-cucina-500-ml_cjZQvZV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_zBdjRFR_fWatzkN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "Mastrolindo Spray",
                                    "data": {
                                        "titolo": "Mastrolindo Spray",
                                        "testo": "Mastrolindo Spray Con Candeggina 500 ml. Per Cucina E Bagno.\nLa cucina è il cuore di tutte le case ed è anche il posto in cui si deve combattere lo sporco più difficile. Questa è la soluzione ideale per sgrassare in modo facile e veloce tutte le superfici della tua cucina, lasciando un fresco profumo.\n\nFonte:\"tuttodetersivi.it\"",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/7x8O664UHz/images/71SFPeEstxL._AC_SY879_.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm49457613",
                            "field1": "AJAX",
                            "field2": "Spray 750ML",
                            "field3": "Assortiti",
                            "field4": "",
                            "description": "AJAX Spray 750ML Assortiti",
                            "grammage": 750,
                            "price": "1.19",
                            "price_label": "€1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Vetri",
                                "Con Candeggina",
                                "Vetri e Superfici Brillanti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Superfici",
                            "blueprint": {
                                "top": 28.84433720421833,
                                "left": 2.368574991111681,
                                "width": 24.30302659810437,
                                "height": 19.09585237663608
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/71cFfDRz9aL._AC_SX466__P8LNga4.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/81pvUTBpXEL._AC_SY741__wrItN2r.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/ccc_eWd92Fe.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_I0mW9iS_xnB0BEu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "AJAX Spray",
                                    "data": {
                                        "specifiche": "Spray con candeggina efficiente, Rimuove lo sporco in modo facile e veloce, Azione igienizzante, Adatto per essere utilizzato in cucina o in bagno, Prodotto di qualità",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/7x8O664UHz/images/re.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm68657654",
                            "field1": "BRAWN ",
                            "field2": "Bucato Sicuro Maxi",
                            "field3": "50 Panni",
                            "field4": "",
                            "description": "BRAWN  Bucato Sicuro Maxi 50 Panni",
                            "grammage": null,
                            "price": "2.99",
                            "price_label": "€2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 48.19216329117485,
                                "left": 2.3685749911116813,
                                "width": 24.30302659810437,
                                "height": 14.313243680983906
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_HeEBAXF_cmROD9t.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm07287455",
                            "field1": "COCCOLINO",
                            "field2": "Ammorbidente Concentrato Moschino 570ML",
                            "field3": "Bloom/Love/Shine",
                            "field4": "",
                            "description": "COCCOLINO Ammorbidente Concentrato Moschino 570ML Bloom/Love/Shine",
                            "grammage": 570,
                            "price": "2.49",
                            "price_label": "€2,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Bloom",
                                "Love",
                                "Shine"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 28.953032856392245,
                                "left": 26.738140208502987,
                                "width": 47.90302659810437,
                                "height": 33.55237411576652
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_SFrqJwU_UWkOJ9H.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "Moschino Ammorbidente Coccolino",
                                    "data": {
                                        "titolo": "Moschino Ammorbidente Coccolino",
                                        "testo": "Ammorbidente ultra concentrato con una fragranza rende omaggio all'amore attraverso la perfetta combinazione di un delicato bouquet di rose e passionali note di lampone e muschio\nCreato da maestri profumieri, l'ammorbidente concentrato coccolino by moschino è disponibile in tre meravigliose fragranze: bloom, shine and love.\nSpeciale Edizione Limitata.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/7x8O664UHz/images/mmmm.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm38584130",
                            "field1": "AJAX",
                            "field2": "1,3LT",
                            "field3": "Assortiti",
                            "field4": "",
                            "description": "AJAX 1,3LT Assortiti",
                            "grammage": 1300,
                            "price": "1.19",
                            "price_label": "€1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 28.73564155204442,
                                "left": 74.83596629545951,
                                "width": 22.763896163321768,
                                "height": 33.55237411576652
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_Hu9VlWU_SXyrNQT.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm31268263",
                            "field1": "MAURY'S MAST",
                            "field2": "Ammorbidente",
                            "field3": "4LT Ass.",
                            "field4": "",
                            "description": "MAURY'S MAST Ammorbidente 4LT Ass.",
                            "grammage": 4000,
                            "price": "1.29",
                            "price_label": "€1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Muschio Bianco e Aloe"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 62.6486850303053,
                                "left": 2.368574991111691,
                                "width": 40.97693964158263,
                                "height": 31.378461072288253
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/41OSSQmLDL._AC__r6S6c5d.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_IJte1AA_wOkfU5S.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Maury's Mast Ammorbidente",
                                    "data": {
                                        "specifiche": "Ammorbidente per bucato, Adatto a tutti i tessuti, Formato risparmio, Confezione: 4 Lt, Profumo Extra durata, Arricchito con Aloe per garantire una morbidezza imbattibile, 50 Lavaggi (seguendo il corretto dosaggio)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/7x8O664UHz/images/41OSSQmLDL._AC_.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm02027431",
                            "field1": "MAURY'S LAVATRICE",
                            "field2": "4LT Ass.",
                            "field3": "",
                            "field4": "",
                            "description": "MAURY'S LAVATRICE 4LT Ass.",
                            "grammage": 4000,
                            "price": "2.39",
                            "price_label": "€2,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Bucato",
                            "blueprint": {
                                "top": 62.6486850303053,
                                "left": 43.28379238241604,
                                "width": 54.31607007636524,
                                "height": 31.05237411576652
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_d8wIYvT_ZCNismc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_3_ya8rIIH.jpg",
                    "products": [
                        {
                            "item_id": "cstm21816611",
                            "field1": "NAPISAN",
                            "field2": "Disinfettante Liquido",
                            "field3": "1200ML",
                            "field4": "",
                            "description": "NAPISAN Disinfettante Liquido 1200ML",
                            "grammage": 1200,
                            "price": "2.99",
                            "price_label": "€2,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 9.23913043478261,
                                "left": 2.094565217391305,
                                "width": 23.856521739130432,
                                "height": 26.52173913043478
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_f0BMSlU_pMP1ud4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm05110622",
                            "field1": "NAPISAN",
                            "field2": "Disinfettante Polvere",
                            "field3": "600gr",
                            "field4": "",
                            "description": "NAPISAN Disinfettante Polvere 600gr",
                            "grammage": 600,
                            "price": "2.99",
                            "price_label": "€2.,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 31.956521739130434,
                                "left": 2.2228260869565224,
                                "width": 23.856521739130432,
                                "height": 13.804347826086952
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_jQOsJa4_jHmEvE6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm80335413",
                            "field1": "LYSOFORM",
                            "field2": "Piatti",
                            "field3": "500ML Igienizzante",
                            "field4": "",
                            "description": "LYSOFORM Piatti 500ML Igienizzante",
                            "grammage": 500,
                            "price": "12900.00",
                            "price_label": "€1,29",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 8.91304347826087,
                                "left": 26.207608695652173,
                                "width": 23.856521739130432,
                                "height": 19.565217391304344
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_8lIiXku_Vzs9kLy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm97193241",
                            "field1": "FAIRY GEL",
                            "field2": "Classico + Limone",
                            "field3": "650ML",
                            "field4": "",
                            "description": "FAIRY GEL Classico + Limone 650ML",
                            "grammage": 650,
                            "price": "2.99",
                            "price_label": "€2.99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Classico",
                                "Limone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 8.90913245657937,
                                "left": 49.935897705495336,
                                "width": 23.846053555732194,
                                "height": 19.55663222175959
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/8001841310466_0_637006608671517367_tW5VGDm.png",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_eaCq09R_9RpGg1i.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm37394282",
                            "field1": "MAURY'S ",
                            "field2": "Pavimenti d'Autore",
                            "field3": "1,5LT Ass.",
                            "field4": "",
                            "description": "MAURY'S  Pavimenti d'Autore 1,5LT Ass.",
                            "grammage": 1500,
                            "price": "0.99",
                            "price_label": "€0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Pavimenti",
                            "blueprint": {
                                "top": 9.126523760927196,
                                "left": 73.97351166165912,
                                "width": 23.589531816601756,
                                "height": 53.25228439567263
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_mBglPfb_NWJ3926.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm43714046",
                            "field1": "MAURY'S MAST",
                            "field2": "Piatti 4LT",
                            "field3": "Limone Aceto",
                            "field4": "",
                            "description": "MAURY'S MAST Piatti 4LT Limone Aceto",
                            "grammage": 4000,
                            "price": "1.59",
                            "price_label": "€1,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Limone",
                                "Aceto"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Casa",
                            "subcategory": "Extra",
                            "blueprint": {
                                "top": 28.58304550005763,
                                "left": 26.260468183398245,
                                "width": 47.5743144252974,
                                "height": 33.90445830871611
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_OBXNOL0_0VZyCdN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm85907262",
                            "field1": "MAURY'S ",
                            "field2": "Piatti Fondi/Piani",
                            "field3": "1kg+100gr",
                            "field4": "",
                            "description": "MAURY'S  Piatti Fondi/Piani 1kg+100gr",
                            "grammage": 1100,
                            "price": "1.99",
                            "price_label": "€1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fondi",
                                "Piani"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 62.4960889783185,
                                "left": 2.147424705137376,
                                "width": 18.97214051225393,
                                "height": 31.187067004368284
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_obwhDLm_UwR1Blc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm60123718",
                            "field1": "MAURY'S",
                            "field2": "Tovaglioli 33X33 1 velo 150 PZ",
                            "field3": "Colori Ass.",
                            "field4": "",
                            "description": "MAURY'S Tovaglioli 33X33 1 velo 150 PZ Colori Ass.",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 62.604784630492404,
                                "left": 21.899598618180857,
                                "width": 23.717792686166973,
                                "height": 31.187067004368284
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_cJrVdgH_bCx7WHr.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm49304132",
                            "field1": "MAURY'S ",
                            "field2": "Tovaglioli Bianchi 33X33",
                            "field3": "2 Veli 100 PZ",
                            "field4": "",
                            "description": "MAURY'S  Tovaglioli Bianchi 33X33 2 Veli 100 PZ",
                            "grammage": null,
                            "price": "0.69",
                            "price_label": "€0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 62.71348028266632,
                                "left": 46.26916383557216,
                                "width": 23.717792686166973,
                                "height": 14.013153960890024
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_QaTeAim_wR9kYZb.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm76428797",
                            "field1": "MAURY'S",
                            "field2": "M/Rotolo Asciugaroll",
                            "field3": "320 gr",
                            "field4": "",
                            "description": "MAURY'S M/Rotolo Asciugaroll 320 gr",
                            "grammage": 320,
                            "price": "0.69",
                            "price_label": "€0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 76.6265237609272,
                                "left": 46.26916383557216,
                                "width": 23.717792686166973,
                                "height": 17.165327873933503
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_JPKL2aL_xzMH7bF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm70657853",
                            "field1": "SCOTTEX ",
                            "field2": "Tuttofare",
                            "field3": "Conf. 6 Rotoli",
                            "field4": "",
                            "description": "SCOTTEX  Tuttofare Conf. 6 Rotoli",
                            "grammage": null,
                            "price": "3.59",
                            "price_label": "€3,59",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 62.71348028266632,
                                "left": 69.99742470513738,
                                "width": 27.565618773123497,
                                "height": 30.969675700020453
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_zkB6FeM_tELZGUH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_4_QhRXHYS.jpg",
                    "products": [
                        {
                            "item_id": "cstm81592170",
                            "field1": "PALMOLIVE",
                            "field2": "Sapone Liquido",
                            "field3": "300ML Ass.",
                            "field4": "",
                            "description": "PALMOLIVE Sapone Liquido 300ML Ass.",
                            "grammage": 300,
                            "price": "0.88",
                            "price_label": "€0,88",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Aloe Vera",
                                "Muschio Bianco d'Oriente",
                                "Latte e Miele"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 8.909132456579364,
                                "left": 2.308695652173913,
                                "width": 27.565618773123497,
                                "height": 21.2957626565422
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/71CpzthMkbL._AC_SY355__FnFfifK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/pim-00000008714789673653-main-20180601-174022_o7AT4RQ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/palmolive-sapone-liquido-mani-aquarium-300-ml_xsnK10A.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_gDiltaL_zCHpu4n.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm22570895",
                            "field1": "PALMOLIVE",
                            "field2": "Shampoo Assortiti",
                            "field3": "350ML",
                            "field4": "",
                            "description": "PALMOLIVE Shampoo Assortiti 350ML",
                            "grammage": 350,
                            "price": "0.79",
                            "price_label": "€0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Aloe Vera",
                                "Menta Selvatica",
                                "Pesca",
                                "Con Perla e Mandorla"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 9.126523760927189,
                                "left": 31.680434782608693,
                                "width": 35.90257529486262,
                                "height": 21.2957626565422
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/55-880525_skXA0gT.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/8714789880495-1024x1024_fA1H29e.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/8714789880501-1024x1024_c0cpOcz.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/8714789880556-1024x1024_QVM7cW6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_0mVUKGv_ELShdN8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm26314071",
                            "field1": "PALMOLIVE",
                            "field2": "Bagnoschiuma Latte e Miele",
                            "field3": "Pelli Delicate 1LT",
                            "field4": "",
                            "description": "PALMOLIVE Bagnoschiuma Latte e Miele Pelli Delicate 1LT",
                            "grammage": 1000,
                            "price": "1.79",
                            "price_label": "€1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Latte",
                                "Miele"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 9.235219413101103,
                                "left": 65.25177253122433,
                                "width": 32.31127094703654,
                                "height": 21.2957626565422
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/eee_HW885M5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/8718951259225_0_636985872286541379_pydx29s.png",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_wet9ZMK_nRm2eVl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm55720182",
                            "field1": "DEPILZERO",
                            "field2": "Crema Depilatoria / Strisce Depilatorie",
                            "field3": "",
                            "field4": "",
                            "description": "DEPILZERO Crema Depilatoria / Strisce Depilatorie",
                            "grammage": null,
                            "price": "1.99",
                            "price_label": "€1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Strisce Viso X20 Argan",
                                "Crema Viso 50ml Argan",
                                "Crema Doccia 200ml Argan",
                                "Crema Corpo 150ml Natura",
                                "Crema Viso Natura 50ml",
                                "Crema Ascelle e Bikini Natura 50ml",
                                "Crema Ascelle e Bikini 74ml",
                                "Strisce Corpo Natura X20",
                                "Strisce Viso Natura X20"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 30.543478260869566,
                                "left": 2.35108695652174,
                                "width": 44.50652173913043,
                                "height": 42.173913043478265
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_7qTg5lu_vf9heZZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm82494162",
                            "field1": "ULTA DOLCE",
                            "field2": "Latte Corpo",
                            "field3": "250ml Ass.",
                            "field4": "",
                            "description": "ULTA DOLCE Latte Corpo 250ml Ass.",
                            "grammage": 250,
                            "price": "1.79",
                            "price_label": "€1,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Latte Idratante",
                                "Latte Lenitivo Idratante",
                                "Latte Nutriente Idratante"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 30.543478260869566,
                                "left": 46.985869565217385,
                                "width": 26.67826086956521,
                                "height": 24.347826086956527
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_VcsyC6q_JZifA6J.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm86868874",
                            "field1": "HUGGIES",
                            "field2": "Mutandina Pants",
                            "field3": "Taglie Ass.",
                            "field4": "",
                            "description": "HUGGIES Mutandina Pants Taglie Ass.",
                            "grammage": null,
                            "price": "3.49",
                            "price_label": "€3,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "13",
                                "14",
                                "15"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 30.43478260869565,
                                "left": 73.6641304347826,
                                "width": 24.113043478260867,
                                "height": 24.347826086956527
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_kxzt0Xf_8Ju7xPL.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "Huggies Pannolino Mutandina",
                                    "data": "https://interattivo.s3.amazonaws.com/7x8O664UHz/video/Huggies_Pannolini_Mutandina_-_Spot_02.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm57866358",
                            "field1": "NEUTROMED",
                            "field2": "Intimo Ascellare",
                            "field3": "200ml Ass.",
                            "field4": "",
                            "description": "NEUTROMED Intimo Ascellare 200ml Ass.",
                            "grammage": 200,
                            "price": "0.79",
                            "price_label": "€0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.10869565217392,
                                "left": 46.985869565217385,
                                "width": 26.550000000000004,
                                "height": 17.826086956521745
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_Voixeua_o0FKC7y.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm59152997",
                            "field1": "MAURY'S",
                            "field2": "Carta Igienica Soft 4 Rotoli ",
                            "field3": "2 Veli Profumata",
                            "field4": "",
                            "description": "MAURY'S Carta Igienica Soft 4 Rotoli  2 Veli Profumata",
                            "grammage": null,
                            "price": "0.69",
                            "price_label": "€0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 69.37821033797896,
                                "left": 2.3211608803144856,
                                "width": 18.083896497648723,
                                "height": 20.088616127713312
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_OArI66s_ORVN6UF.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Soft Rotoli",
                                    "data": {
                                        "specifiche": "Carta Igienica per bagno, Veli per rotolo: 2, Strappi per rotolo: 212, Diametro Rotolo: 12.5 cm, Lunghezza strappo: 12 cm, Altezza strappo: 9.6 cm, Lunghezza Rotolo: 25.44 mt, Tipo di lavorazione: Liscia, Colore: Bianco, Peso 4 rotoli: 350 gr, Fonte:\"maurysonline.it\"",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/7x8O664UHz/images/SoftRotoli.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm87008196",
                            "field1": "MAURY'S",
                            "field2": "Carta Igienica 4 Rotoli 2 Veli ",
                            "field3": "Deco 765gr",
                            "field4": "",
                            "description": "MAURY'S Carta Igienica 4 Rotoli 2 Veli  Deco 765gr",
                            "grammage": 765,
                            "price": "1.39",
                            "price_label": "€1,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Intima",
                            "blueprint": {
                                "top": 72.93478260869566,
                                "left": 21.67608695652174,
                                "width": 19.752173913043478,
                                "height": 20.86956521739131
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_1JIdm8K_t83Kruh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm16061232",
                            "field1": "HYDRA VIVA",
                            "field2": "Doccia Shampoo",
                            "field3": "300ml Ass.",
                            "field4": "",
                            "description": "HYDRA VIVA Doccia Shampoo 300ml Ass.",
                            "grammage": 300,
                            "price": "0.69",
                            "price_label": "€0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 72.93478260869566,
                                "left": 41.684782608695656,
                                "width": 31.6804347826087,
                                "height": 12.500000000000005
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_4gIYJyU_EXClRnc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm33592842",
                            "field1": "HYDRA VIVA",
                            "field2": "Shampoo",
                            "field3": "300ml",
                            "field4": "",
                            "description": "HYDRA VIVA Shampoo 300ml",
                            "grammage": 300,
                            "price": "0.79",
                            "price_label": "€0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Capelli",
                            "blueprint": {
                                "top": 79.67391304347827,
                                "left": 42.06956521739131,
                                "width": 31.6804347826087,
                                "height": 13.913043478260875
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_axPlKff_hUxDqtw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm81698207",
                            "field1": "MAURY'S ",
                            "field2": "Deo Spray Uomo/Donna",
                            "field3": "150ml",
                            "field4": "",
                            "description": "MAURY'S  Deo Spray Uomo/Donna 150ml",
                            "grammage": 150,
                            "price": "0.69",
                            "price_label": "€0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 55.0,
                                "left": 73.75,
                                "width": 23.85652173913044,
                                "height": 38.47826086956522
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_96IFmnz_Afb6qUa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_5_C6hX1eg.jpg",
                    "products": [
                        {
                            "item_id": "cstm98551166",
                            "field1": "GILLETTE GEL",
                            "field2": "Satin Care Sensitive",
                            "field3": "200ml",
                            "field4": "",
                            "description": "GILLETTE GEL Satin Care Sensitive 200ml",
                            "grammage": 200,
                            "price": "1.99",
                            "price_label": "€1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 9.021739130434783,
                                "left": 2.308695652173913,
                                "width": 23.85652173913044,
                                "height": 16.739130434782616
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_uCFqaOn_Dh2EuFw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm66117679",
                            "field1": "DENIM",
                            "field2": "Crema Da Barba",
                            "field3": "Original 100ml",
                            "field4": "",
                            "description": "DENIM Crema Da Barba Original 100ml",
                            "grammage": 100,
                            "price": "0.69",
                            "price_label": "€0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 9.021739130434783,
                                "left": 26.03695652173913,
                                "width": 23.85652173913044,
                                "height": 16.739130434782616
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_zbeV0ou_TUc2JhB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm84842242",
                            "field1": "DENIM",
                            "field2": "Doccia Gel",
                            "field3": "250ml Ass.",
                            "field4": "",
                            "description": "DENIM Doccia Gel 250ml Ass.",
                            "grammage": 250,
                            "price": "0.79",
                            "price_label": "€0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 9.021739130434783,
                                "left": 50.02173913043478,
                                "width": 23.85652173913044,
                                "height": 16.739130434782616
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_vt9zMSM_xBtDdDQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm75037237",
                            "field1": "DENIM",
                            "field2": "Deodoranti Spray ",
                            "field3": "150ml Ass.",
                            "field4": "",
                            "description": "DENIM Deodoranti Spray  150ml Ass.",
                            "grammage": 150,
                            "price": "0.99",
                            "price_label": "€0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 9.021739130434783,
                                "left": 74.00652173913043,
                                "width": 23.471739130434784,
                                "height": 16.739130434782616
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_lRBXR0k_s7hkQhh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm48748992",
                            "field1": "GILLETTE",
                            "field2": "Schiuma da Barba",
                            "field3": "200ml Assortita",
                            "field4": "",
                            "description": "GILLETTE Schiuma da Barba 200ml Assortita",
                            "grammage": 200,
                            "price": "0.99",
                            "price_label": "€0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 25.97826086956522,
                                "left": 2.4369565217391305,
                                "width": 23.72826086956522,
                                "height": 16.739130434782616
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_qOwg2UV_Ulqbfj6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "A Cosa Serve La Schiuma Da Barba? GILLETTE ce lo spiega!",
                                    "data": "https://interattivo.s3.amazonaws.com/7x8O664UHz/video/Gillette_Schiuma_Da_Barba_Spot.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm58323697",
                            "field1": "MAURY'S",
                            "field2": "Lame Extra 3+1",
                            "field3": "Donna/Uomo",
                            "field4": "",
                            "description": "MAURY'S Lame Extra 3+1 Donna/Uomo",
                            "grammage": null,
                            "price": "0.69",
                            "price_label": "€0,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Donna",
                                "Uomo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 43.04347826086956,
                                "left": 2.308695652173913,
                                "width": 23.72826086956522,
                                "height": 16.739130434782616
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_RmRu9vI_O2unTAl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm53039047",
                            "field1": "COLGATE ",
                            "field2": "Dentifricio",
                            "field3": "100ml Ass.",
                            "field4": "",
                            "description": "COLGATE  Dentifricio 100ml Ass.",
                            "grammage": 100,
                            "price": "0.79",
                            "price_label": "€0,79",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Protection Caries",
                                "Triple Action",
                                "Deep Clean Whitening"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Orale",
                            "blueprint": {
                                "top": 25.97826086956522,
                                "left": 26.293478260869563,
                                "width": 47.45652173913044,
                                "height": 33.80434782608696
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_lWHc4Hi_MyXnxLh.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "COLGATE Dentifricio",
                                    "data": "https://interattivo.s3.amazonaws.com/7x8O664UHz/video/Colgate_Il_Sorriso_%C3%A8_la_Nostra_Forza.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm05609428",
                            "field1": "FRIA ",
                            "field2": "Struccantiepiù Latte Detergente Detox/Nutriente/Night Calming",
                            "field3": "20 Salviette",
                            "field4": "",
                            "description": "FRIA  Struccantiepiù Latte Detergente Detox/Nutriente/Night Calming 20 Salviette",
                            "grammage": null,
                            "price": "1.49",
                            "price_label": "€1,49",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Latte Detergente Detox",
                                "Nutriente",
                                "Night Calming"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Viso",
                            "blueprint": {
                                "top": 25.869565217391305,
                                "left": 73.75,
                                "width": 23.72826086956522,
                                "height": 16.95652173913044
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_mHocOuG_DRNmpUK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm56104322",
                            "field1": "MAURY'S",
                            "field2": "Salviette C/Tappo 72 PZ Milleusi C/Coperchio ",
                            "field3": "Best Quality Blue/Red",
                            "field4": "",
                            "description": "MAURY'S Salviette C/Tappo 72 PZ Milleusi C/Coperchio  Best Quality Blue/Red",
                            "grammage": null,
                            "price": "0.99",
                            "price_label": "€0,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Blue",
                                "Red"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 42.82608695652174,
                                "left": 73.75,
                                "width": 23.72826086956522,
                                "height": 16.95652173913044
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_mX7sNt5_UCHIxXZ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm55607338",
                            "field1": "HYDRA VIVA",
                            "field2": "Crema Corpo 500+250ml Omaggio",
                            "field3": "Assortiti",
                            "field4": "",
                            "description": "HYDRA VIVA Crema Corpo 500+250ml Omaggio Assortiti",
                            "grammage": 750,
                            "price": "1.99",
                            "price_label": "€1,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 59.78260869565217,
                                "left": 2.308695652173913,
                                "width": 23.72826086956522,
                                "height": 34.02173913043479
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_7NH8Wbj_2Jd7vo8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm85195191",
                            "field1": "SPUMA DI SCIAMPAGNA",
                            "field2": "Sapone Liquido",
                            "field3": "400ml Ass.",
                            "field4": "",
                            "description": "SPUMA DI SCIAMPAGNA Sapone Liquido 400ml Ass.",
                            "grammage": 400,
                            "price": "2.39",
                            "price_label": "€2,39",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 59.78260869565217,
                                "left": 73.87826086956521,
                                "width": 23.72826086956522,
                                "height": 34.02173913043479
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_zQptGgW_OZ93iSx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm98993295",
                            "field1": "ACQUA SOLARE ",
                            "field2": "Rinfrascante/Tonificante",
                            "field3": "450ml",
                            "field4": "",
                            "description": "ACQUA SOLARE  Rinfrascante/Tonificante 450ml",
                            "grammage": 450,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Solari",
                            "blueprint": {
                                "top": 59.89130434782608,
                                "left": 26.293478260869563,
                                "width": 26.806521739130435,
                                "height": 12.717391304347833
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_SJCk9Nr_9jHr4IR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm06145257",
                            "field1": "CREMA SOLARE",
                            "field2": "Viso SPF 15/SPF 30",
                            "field3": "75 ml",
                            "field4": "",
                            "description": "CREMA SOLARE Viso SPF 15/SPF 30 75 ml",
                            "grammage": 75,
                            "price": "3.99",
                            "price_label": "€3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "15",
                                "30"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Solari",
                            "blueprint": {
                                "top": 59.89130434782608,
                                "left": 52.71521739130435,
                                "width": 20.906521739130437,
                                "height": 12.717391304347833
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_3omhcJ3_fck4tfX.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm54184828",
                            "field1": "LATTE SOLARE",
                            "field2": "Anti Age SPF6/10/15/20/30",
                            "field3": "",
                            "field4": "",
                            "description": "LATTE SOLARE Anti Age SPF6/10/15/20/30",
                            "grammage": 200,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "6",
                                "10",
                                "15",
                                "20",
                                "30"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Solari",
                            "blueprint": {
                                "top": 72.17391304347827,
                                "left": 26.293478260869563,
                                "width": 20.906521739130437,
                                "height": 11.521739130434788
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_w0uIkFV_Tq7iI2O.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm35962408",
                            "field1": "BALSAMO CORPO",
                            "field2": "Sotto La Doccia Doposole",
                            "field3": "200ml Tubo",
                            "field4": "",
                            "description": "BALSAMO CORPO Sotto La Doccia Doposole 200ml Tubo",
                            "grammage": 200,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Corpo",
                            "blueprint": {
                                "top": 71.41304347826087,
                                "left": 58.87173913043478,
                                "width": 14.750000000000004,
                                "height": 11.521739130434788
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_LHNEylw_B8p4nhc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm71511260",
                            "field1": "PRE E DOPO SOLE",
                            "field2": "Idratate ",
                            "field3": "200ml",
                            "field4": "",
                            "description": "PRE E DOPO SOLE Idratate  200ml",
                            "grammage": 200,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Solari",
                            "blueprint": {
                                "top": 82.82608695652173,
                                "left": 26.335869565217394,
                                "width": 14.23695652173913,
                                "height": 10.652173913043478
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_WqEUvPZ_8mI8GH2.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm71965022",
                            "field1": "STICK SOLARE",
                            "field2": "SPF 50+/SPF 30",
                            "field3": "10ml",
                            "field4": "",
                            "description": "STICK SOLARE SPF 50+/SPF 30 10ml",
                            "grammage": 10,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "50+",
                                "30"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Solari",
                            "blueprint": {
                                "top": 82.82608695652173,
                                "left": 40.57282608695652,
                                "width": 14.23695652173913,
                                "height": 10.652173913043478
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_LeNXxCs_ID7fYUn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm96046290",
                            "field1": "SPRAY SOLARE",
                            "field2": "Bimbi",
                            "field3": "SPF30/50+ 150ml",
                            "field4": "",
                            "description": "SPRAY SOLARE Bimbi SPF30/50+ 150ml",
                            "grammage": 150,
                            "price": "3.99",
                            "price_label": "€ 3,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "30",
                                "50+"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Igiene Persona",
                            "subcategory": "Solari",
                            "blueprint": {
                                "top": 82.93478260869566,
                                "left": 54.938043478260866,
                                "width": 18.854347826086958,
                                "height": 10.652173913043478
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_tHfnDbc_FC4SS9s.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_6_oTVEmjf.jpg",
                    "products": [
                        {
                            "item_id": "cstm89819067",
                            "field1": "LECHAT",
                            "field2": "Multipack",
                            "field3": "4X100gr",
                            "field4": "",
                            "description": "LECHAT Multipack 4X100gr",
                            "grammage": 400,
                            "price": "0.75",
                            "price_label": "€ 0,75",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 8.91304347826087,
                                "left": 2.2228260869565224,
                                "width": 25.65217391304348,
                                "height": 33.91304347826087
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_AYeR5LO_D77Oizy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm61952121",
                            "field1": "SHEBA",
                            "field2": "Patè 85gr",
                            "field3": "Ass.",
                            "field4": "",
                            "description": "SHEBA Patè 85gr Ass.",
                            "grammage": 85,
                            "price": "0.45",
                            "price_label": "€ 0,45",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Selvaggina",
                                "Vitello",
                                "Tacchino",
                                "Manzo",
                                "Salmone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 9.021739130434783,
                                "left": 28.131521739130434,
                                "width": 43.73695652173913,
                                "height": 16.956521739130434
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_CoTSoz1_KhAmhvu.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm57183464",
                            "field1": "LECHAT",
                            "field2": "Execellence",
                            "field3": "400gr Ass.",
                            "field4": "",
                            "description": "LECHAT Execellence 400gr Ass.",
                            "grammage": 400,
                            "price": "1.19",
                            "price_label": "€ 1,19",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Adult",
                                "Indoor",
                                "Appetito Difficile",
                                "Sterilized"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 26.195652173913047,
                                "left": 28.131521739130434,
                                "width": 43.73695652173913,
                                "height": 33.26086956521739
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_a7FRrZv_HjUc4Ql.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm36566957",
                            "field1": "MIGLIOR GATTO",
                            "field2": "Croccantini",
                            "field3": "2KG Ass.",
                            "field4": "",
                            "description": "MIGLIOR GATTO Croccantini 2KG Ass.",
                            "grammage": 2000,
                            "price": "2.20",
                            "price_label": "€ 2,20",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 8.91304347826087,
                                "left": 72.125,
                                "width": 25.395652173913042,
                                "height": 50.8695652173913
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_NiVfUev_G9t4ziU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm12555060",
                            "field1": "MIGLIOR GATTO",
                            "field2": "Sterilized Vaschetta",
                            "field3": "100gr Ass.",
                            "field4": "",
                            "description": "MIGLIOR GATTO Sterilized Vaschetta 100gr Ass.",
                            "grammage": 100,
                            "price": "0.35",
                            "price_label": "€ 0,35",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 60.0,
                                "left": 2.4369565217391305,
                                "width": 25.395652173913042,
                                "height": 16.739130434782606
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_6JaH05x_BBUq6Zq.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm90773732",
                            "field1": "CAT STICK MINI",
                            "field2": "18gr 3PZ",
                            "field3": "",
                            "field4": "",
                            "description": "CAT STICK MINI 18gr 3PZ",
                            "grammage": 18,
                            "price": "0.65",
                            "price_label": "€ 0,65",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 76.84782608695652,
                                "left": 2.5652173913043477,
                                "width": 25.395652173913042,
                                "height": 16.956521739130434
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_15Xe2G1_IqWaVAs.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm31921411",
                            "field1": "SPOT-ON",
                            "field2": "Amti Parassitario",
                            "field3": "Taglia Grande 3 dosi",
                            "field4": "",
                            "description": "SPOT-ON Amti Parassitario Taglia Grande 3 dosi",
                            "grammage": null,
                            "price": "7.99",
                            "price_label": "€ 7,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Care",
                            "blueprint": {
                                "top": 59.89130434782609,
                                "left": 28.00326086956522,
                                "width": 18.854347826086958,
                                "height": 11.84782608695652
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_8HIhT9N_9T09nND.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "Lo Sapevi Che...",
                                    "data": {
                                        "titolo": "Lo Sapevi Che...",
                                        "testo": "Scegliere l'antiparassitario è fondamentale non solo per proteggere il cane o il gatto, ma anche per noi stessi. Un'infestazione di pulci non è una cosa da sottovalutare e nemmeno piacevole (nè per il cane nè per noi), per questo è bene munirsi e cominciare il trattamento antiparassitario il prima possibile, anche prima dell'inizio della bella stagione.\n\nFonte:\"Pacopetshop.it\"",
                                        "img": []
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm01984933",
                            "field1": "SPOT-ON",
                            "field2": "Anti Parassitario",
                            "field3": "Taglia Media 3 dosi",
                            "field4": "",
                            "description": "SPOT-ON Anti Parassitario Taglia Media 3 dosi",
                            "grammage": null,
                            "price": "6.50",
                            "price_label": "€ 6,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Care",
                            "blueprint": {
                                "top": 59.67391304347826,
                                "left": 46.857608695652175,
                                "width": 20.393478260869564,
                                "height": 11.84782608695652
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_xtoCdUx_S0cYaBa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm68956488",
                            "field1": "SPOT-ON",
                            "field2": "Anti Parassitario",
                            "field3": "Cani/Gatti 3 Dosi",
                            "field4": "",
                            "description": "SPOT-ON Anti Parassitario Cani/Gatti 3 Dosi",
                            "grammage": null,
                            "price": "5.50",
                            "price_label": "€ 5,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Care",
                            "blueprint": {
                                "top": 59.78260869565217,
                                "left": 68.4054347826087,
                                "width": 29.243478260869566,
                                "height": 14.021739130434783
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_WzpTjNf_BfRX4yY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm82967158",
                            "field1": "REPELT",
                            "field2": "Collare Anti Parassitario ",
                            "field3": "Ass.",
                            "field4": "",
                            "description": "REPELT Collare Anti Parassitario  Ass.",
                            "grammage": null,
                            "price": "2.88",
                            "price_label": "€ 2,88",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Care",
                            "blueprint": {
                                "top": 72.93478260869566,
                                "left": 46.857608695652175,
                                "width": 21.932608695652174,
                                "height": 20.32608695652174
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_65wEH5M_qrgnGUU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm31090106",
                            "field1": "SPRAY",
                            "field2": "Anti Parassitario",
                            "field3": "Per Cani 250ml",
                            "field4": "",
                            "description": "SPRAY Anti Parassitario Per Cani 250ml",
                            "grammage": 250,
                            "price": "7.69",
                            "price_label": "€ 7,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Care",
                            "blueprint": {
                                "top": 73.26086956521739,
                                "left": 66.73804347826086,
                                "width": 20.008695652173913,
                                "height": 20.32608695652174
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_VkFjSTj_QxF6ZTN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm51970817",
                            "field1": "SHAMPOO",
                            "field2": "Anti Parassitario",
                            "field3": "Cani 250ml",
                            "field4": "",
                            "description": "SHAMPOO Anti Parassitario Cani 250ml",
                            "grammage": 250,
                            "price": "2.88",
                            "price_label": "€ 2,88",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Care",
                            "blueprint": {
                                "top": 73.47826086956522,
                                "left": 76.74239130434783,
                                "width": 20.778260869565216,
                                "height": 20.32608695652174
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_XRolUjE_mJibqop.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm85959910",
                            "field1": "WHISKAS PRANZETTI",
                            "field2": "Carni Bianche/Pesce",
                            "field3": "50grX6",
                            "field4": "",
                            "description": "WHISKAS PRANZETTI Carni Bianche/Pesce 50grX6",
                            "grammage": 50,
                            "price": "1.69",
                            "price_label": "€ 1,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Carni Bianche",
                                "Pranzetti"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Pet",
                            "subcategory": "Food",
                            "blueprint": {
                                "top": 42.76611625917094,
                                "left": 2.42642320085929,
                                "width": 25.310418904403864,
                                "height": 16.938229350616247
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/products/cropped_image_kQmvnaE_Eawomsm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "WHISKAS PRANZETTI",
                                    "data": {
                                        "titolo": "WHISKAS PRANZETTI",
                                        "testo": "I Pranzetti di Whiskas varieranno deliziosamente la dieta del vostro gatto. Grazie alle pratiche bustine monoporzione potrete offrire ogni giorno al vostro micio quello che gli piace di più - un alimento sempre fresco e succoso pronto per la sua ciotola! Ogni busta monoporzione costituisce un pasto completo che fornisce al gatto tutti i nutrienti essenziali di cui ha bisogno per una vita sana e attiva. Il multipack è costituito da 3 gusti delicati. Per offrire al vostro amico di zampa un piacere del tutto naturale, Whiskas Pranzetti non contiene aromi o conservanti artificiali. L'ampio assortimento di varianti offre al gatto una prelibatezza delicata che non gli verrà mai a noia.\n\nFonte:\"zooplus.it\"",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/7x8O664UHz/images/Whiskas.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_7_G721rIh.jpg",
                    "products": []
                },
                {
                    "number": 8,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_8_oVFFhpI.jpg",
                    "products": []
                },
                {
                    "number": 9,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_9_4S9a1KI.jpg",
                    "products": []
                },
                {
                    "number": 10,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_10_xcMhz1O.jpg",
                    "products": []
                },
                {
                    "number": 11,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_11_YVoqJJo.jpg",
                    "products": []
                },
                {
                    "number": 12,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_12_y1f3z7Y.jpg",
                    "products": []
                },
                {
                    "number": 13,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_13_DLJaA8E.jpg",
                    "products": []
                },
                {
                    "number": 14,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_14_uShbEPn.jpg",
                    "products": []
                },
                {
                    "number": 15,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_15_3hXK0Fr.jpg",
                    "products": []
                },
                {
                    "number": 16,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/page_16_QcwmjcX.jpg",
                    "products": []
                },
                {
                    "number": 17,
                    "image_file": "https://interattivo.s3.amazonaws.com/7x8O664UHz/pages/Indice_maurys-02_Npn73Oi.png",
                    "products": []
                }
            ]
        }

    }

}

const lefletSwitch = () => {
    return leaflets.magazziniMaurys;
}

export const serverResponse = lefletSwitch();